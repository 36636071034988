<template>
	<div id="newsletter">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row dense>
				<v-col cols="12" md="auto">
					<v-select 
						:items="brands" 
						v-model="marca"
						label="Brands">
					</v-select>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						label="Model/Collection"
						v-model="modelo">
					</v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-text-field
						label="Discount"
						suffix="%"
						v-model="porcen"></v-text-field>
				</v-col>
				<v-col cols="12" md="1">
					<v-btn
						large
						color="green darken-1 white--text"
						:disabled="modelo === '' || porcen === ''"
						@click="fnAddTask">
						<v-icon left>mdi-plus</v-icon>Add
					</v-btn>
				</v-col>
				<v-col cols="12" md="1">
					<v-btn
						large
						color="orange darkeb-1 white--text"
						:disabled="fnCount('X') == 0"
						@click="fnCloseAll">
						Close all finished
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<v-data-table
			:items="listado"
			:headers="cabecera"
			:loading="stLoad"
			hide-default-footer
			:items-per-page="-1">

			<template v-slot:[`item.discount`]="{ item }">
				<div>{{ item.discount }}%</div>
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<div>{{ fnConvSt(item.status) }}</div>
			</template>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn @click="fnPlay(item.id)" v-if="item.status === 'N'" color="green lighten-1" icon><v-icon>mdi-play</v-icon></v-btn>
				<v-btn @click="fnPause(item.id)" v-else-if="item.status === 'W' || item.status === 'P'" color="yellow darken-3" icon><v-icon>mdi-pause</v-icon></v-btn>
				<v-btn @click="fnRedo(item.id)" v-if="item.status === 'X' || item.status === 'E'" color="cyan darken-2" icon><v-icon>mdi-sync-alert</v-icon></v-btn>
				<v-btn @click="fnDelete(item.id)" :disabled="item.status !== 'X' && item.status !== 'N' && item.status !== 'E'" color="red lighten-1" icon><v-icon>mdi-delete</v-icon></v-btn>
			</template>

		</v-data-table>
	</div>
</template>

<script>
import axios from "axios"

export default {
	name: 'secron',
	metaInfo: {
		title: 'STEVEN Cron Discount'
	},
	data() {
		return {
			brands: [],
			marca: null,
			listado: [],
			cabecera: [
				{ text: 'id', value: 'id' },
				{ text: 'brand', value: 'job' },
				{ text: 'catalog', value: 'model' },
				{ text: 'Discount', value: 'discount', align: 'center' },
				{ text: 'status', value: 'status' },
				{ text: 'log', value: 'process', align: 'right' },
				{ text: 'action', value: 'action', align: 'center' }
			],
			modelo: '',
			porcen: '',
			stLoad: false
		}
	},
	mounted() {
		axios.post('/se-brands')
			.then(response => this.brands = response.data)
		this.fnLoader()
		this.interval = setInterval(function() {
			this.fnLoader()
		}.bind(this), 10000)
		this.$store.state.titulofix = 'STEVEN Discount Manager'
	},
	beforeDestroy() {
		clearInterval(this.interval)
	},
	methods: {
		fnLoader() {
			this.stLoad = true
			axios.post('/se-cronjobs')
				.then(response => {
					this.listado = response.data
					this.stLoad = false
				})
		},
		fnAddTask() {
			axios.post('/se-addtask', { brand: this.marca, model: this.modelo, sale: this.porcen })
				.then(() => {
					this.$successsnd()
					this.fnLoader()
				})
		},
		fnPlay(id) {
			axios.post('/se-chgcronst', { id: id, status: 'W' })
				.then( () => {
					this.$successsnd()
					this.fnLoader()
				} )
		},
		fnPause(id) {
			axios.post('/se-chgcronst', { id: id, status: 'N' })
				.then( () => {
					this.$successsnd()
					this.fnLoader()
				} )
		},
		fnDelete(id) {
			axios.post('/se-delcrontsk', { id: id })
				.then( () => {
					this.$successsnd()
					this.fnLoader()
				} )
		},
		fnRedo(id) {
			axios.post('/se-chgcronst', { id: id, status: 'N' })
				.then( () => {
					this.$successsnd()
					this.fnLoader()
				} )
		},
		fnCount(status) {
			const filtro = this.listado.filter( val => val.status == status )
			return filtro.length
		},
		fnCloseAll() {
			axios.post('/se-cronclsall')
				.then( response => {
					this.$successsnd()
					console.log(response)
				} )
		},
		fnConvSt(status) {
			let text = ''
			switch(status) {
				case 'N':
					text = 'STOP'
					break
				case 'W':
					text = 'PENDING'
					break
				case 'P':
					text = 'PROCESSING'
					break
				case 'E':
					text = 'ERROR'
					break
				case 'X':
					text = 'FINISHED'
					break
				default:
					text = 'UNKNOW'
			}
			return text
		}
	}
}
</script>